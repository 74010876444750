<template>
  <div>
    <div class="content">
      <div class="card" style="display: flex">
        <div class="left">
          <el-button
            :loading="exportLoading"
            type="primary"
            plain
            @click="handleExport"
            icon="el-icon-upload2"
            >导出</el-button
          >
        </div>
        <div class="line"></div>
        <div :style="{ height: height }" class="wrapper">
          <div class="middle" id="middle">
            <el-form
              :model="cond"
              class="form"
              style="overflow: hidden; display: flex; flex-wrap: wrap"
              label-width="100px"
            >
              <el-form-item label="活动名称" style="margin-right: 30px">
                <el-input
                  clearable
                  v-model="verifCond.market_title"
                  autocomplete="off"
                  placeholder="请输入活动名称"
                ></el-input
              ></el-form-item>
              <el-form-item label="商品名称" style="margin-right: 30px">
                <el-input
                  clearable
                  v-model="verifCond.goods_name"
                  autocomplete="off"
                  placeholder="请输入商品名称"
                ></el-input
              ></el-form-item>
              <el-form-item label="用户名称" style="margin-right: 30px">
                <el-input
                  clearable
                  v-model="verifCond.username"
                  autocomplete="off"
                  placeholder="请输入用户名称"
                ></el-input
              ></el-form-item>
              <el-form-item label="用户手机号" style="margin-right: 30px">
                <el-input
                  clearable
                  v-model="verifCond.mobile"
                  autocomplete="off"
                  placeholder="请输入用户手机号"
                ></el-input
              ></el-form-item>
              <el-form-item label="核销人员" style="margin-right: 30px">
                <el-input
                  clearable
                  v-model="verifCond.verif_user_id"
                  autocomplete="off"
                  placeholder="请输入核销人员"
                ></el-input
              ></el-form-item>
              <el-form-item label="核销状态">
                <el-select
                  clearable
                  v-model="verifCond.is_verif"
                  placeholder="请选择核销状态"
                  style="width: 200px; margin-right: 10px"
                >
                  <el-option label="未核销" :value="10"></el-option>
                  <el-option label="已核销" :value="20"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="核销时间" style="margin-right: 30px">
                <el-date-picker
                  type="daterange"
                  v-model="verifCond.verif_time"
                  range-separator="至"
                  start-placeholder="核销开始日期"
                  value-format="yyyy-MM-dd"
                  end-placeholder="核销结束日期"
                ></el-date-picker
              ></el-form-item>
              <el-form-item label="中奖时间" style="margin-right: 30px">
                <el-date-picker
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  v-model="verifCond.hit_time"
                  range-separator="至"
                  start-placeholder="中奖开始日期"
                  end-placeholder="中奖结束日期"
                ></el-date-picker
              ></el-form-item>
              <el-form-item label="付款时间" style="margin-right: 30px">
                <el-date-picker
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  v-model="verifCond.pay_time"
                  range-separator="至"
                  start-placeholder="付款开始日期"
                  end-placeholder="付款结束日期"
                ></el-date-picker
              ></el-form-item>
            </el-form>
          </div>
        </div>
        <div>
          <el-button
            @click="handleHeight"
            style="
              width: 40px;
              height: 40px;
              line-height: 36px;
              text-align: center;
              padding: 0;
            "
          >
            <i class="el-icon-caret-bottom" v-if="height === '55px'"></i>
            <i class="el-icon-caret-top" v-else></i>
          </el-button>
          <el-button type="primary" @click="getVerifList()" style="height: 40px"
            >搜索</el-button
          >
          <el-button @click="resetVerifList" style="height: 40px"
            >重置</el-button
          >
        </div>
      </div>
      <div class="writeData">
        <div style="margin-left: 30px">
          核销总数量<span style="color: #2791ff; margin-left: 10px">{{
            verif_total_num
          }}</span>
        </div>
        <div class="line"></div>
        <div>
          未核销数量<span style="color: #2791ff; margin-left: 10px">{{
            un_verif
          }}</span>
        </div>
        <div class="line"></div>
        <div>
          已核销数量<span style="color: #2791ff; margin-left: 10px">{{
            verif
          }}</span>
        </div>
        <div class="line"></div>
      </div>
    </div>
    <div class="table" style="margin-top: 10px; border: 1px solid #e8e8e8">
      <el-table :data="verifList" stripe height="700">
        <el-table-column
          align="center"
          type="index"
          label="序号"
        ></el-table-column>
        <el-table-column align="center" prop="headImg" label="头像">
          <template slot-scope="scope">
            <div class="headImg">
              <img :src="scope.row.head_img" /></div></template
        ></el-table-column>
        <el-table-column
          align="center"
          prop="username"
          label="用户名称"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="mobile"
          label="手机号"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="market_template_type_name"
          label="活动类型"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="market_title"
          label="活动名称"
        ></el-table-column>
        <el-table-column align="center" prop="goods_pic" label="奖品图片">
          <template slot-scope="scope">
            <div class="goods_Img">
              <img :src="scope.row.goods_pic" /></div></template
        ></el-table-column>
        <el-table-column
          align="center"
          prop="goods_name"
          label="商品名称"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="hit_time"
          label="中奖时间"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="paid_in_amount"
          label="付款金额"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="pay_time"
          width="200"
          label="付款时间"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="verif_hierarchy_name"
          label="选择领奖门店"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="final_verif_hierarchy_name"
          label="核销门店"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="verif_user_name"
          label="核销人"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="verif_time"
          label="核销时间"
        ></el-table-column>
        <el-table-column align="center" prop="is_verif" label="核销状态">
          <template slot-scope="scope">
            {{ scope.row.is_verif === 10 ? "未核销" : "已核销" }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="action" label="核销状态">
          <template slot-scope="scope">
            <el-button type="text" @click="handleVerif(scope.row)">{{
              scope.row.is_verif === 20 ? "查看详情" : "核销"
            }}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagina">
      <el-pagination
        background
        layout="total, prev, pager, next, jumper"
        :total="verifTotal"
        :page-size="15"
        @current-change="handleCurrentVerifChange"
      ></el-pagination>
    </div>
    <el-dialog
      :visible.sync="visiable"
      width="30%"
      title="核销详情"
      :destroy-on-close="true"
      :before-close="cancelVerifer"
    >
      <div class="title">
        <div class="span"></div>
        会员信息
      </div>
      <div style="display: flex">
        <div style="display: flex; width: 50%">
          <div style="color: #909399; margin-right: 20px">会员名称</div>
          <div>{{ veriferInfo.username }}</div>
        </div>
        <div style="display: flex; width: 50%">
          <div style="color: #909399; margin-right: 20px">手机号</div>
          <div>{{ veriferInfo.mobile }}</div>
        </div>
      </div>
      <div class="title" style="margin-top: 15px">
        <div class="span"></div>
        奖品信息
      </div>
      <div>
        <div style="display: flex; width: 50%">
          <div style="color: #909399; margin-right: 20px">奖品名称</div>
          <div>
            {{
              veriferInfo.market_goods_json &&
              veriferInfo.market_goods_json.goods_name
            }}
          </div>
        </div>
      </div>
      <div>
        <div style="margin-top: 15px; display: flex; width: 50%">
          <div style="color: #909399; margin-right: 20px">奖品图片</div>
          <div>
            <img
              :src="
                veriferInfo.market_goods_json &&
                veriferInfo.market_goods_json.goods_pic
              "
              style="width: 94px; height: 64px; object-fit: cover"
            />
          </div>
        </div>
      </div>
      <div>
        <div style="margin-top: 15px; display: flex; width: 50%">
          <div style="color: #909399; margin-right: 20px">获奖时间</div>
          <div>{{ veriferInfo.create_time }}</div>
        </div>
      </div>
      <div class="title" style="margin-top: 15px">
        <div class="span"></div>
        兑奖信息
      </div>
      <div style="display: flex">
        <div style="display: flex; width: 50%">
          <div style="color: #909399; margin-right: 20px">领取门店</div>
          <div>{{ veriferInfo.hierarchy_name }}</div>
        </div>
        <div style="display: flex; width: 50%">
          <div style="color: #909399; margin-right: 20px">门店地址</div>
          <div>{{ veriferInfo.hierarchy_addr }}</div>
        </div>
      </div>
      <div style="display: flex; margin-top: 10px">
        <div style="display: flex; width: 50%">
          <div style="color: #909399; margin-right: 20px">门店电话</div>
          <div>{{ veriferInfo.hierarchy_phone_num }}</div>
        </div>
      </div>
      <div style="position: absolute; top: 35%; right: 10%; z-index: 5555">
        <img
          src="@/assets/images/activity/writeOffed.svg"
          style="transform: rotate(-10deg)"
          v-if="veriferInfo.is_verif === 20"
        />
        <img
          src="@/assets/images/activity/writeOff.svg"
          style="transform: rotate(-10deg)"
          v-if="veriferInfo.is_verif === 10"
        />
      </div>
      <div
        class="title"
        style="margin-top: 15px"
        v-if="veriferInfo.is_verif === 20"
      >
        <div class="span"></div>
        核销信息
      </div>
      <div style="display: flex" v-if="veriferInfo.is_verif === 20">
        <div style="display: flex; width: 50%">
          <div style="color: #909399; margin-right: 20px">核销人员</div>
          <div>{{ veriferInfo.verif_user_name }}</div>
        </div>
        <div style="display: flex; width: 50%">
          <div style="color: #909399; margin-right: 20px">核销时间</div>
          <div>{{ veriferInfo.verif_time }}</div>
        </div>
      </div>
      <div
        style="display: flex; margin-top: 10px"
        v-if="veriferInfo.is_verif === 20"
      >
        <div style="display: flex; width: 50%">
          <div style="color: #909399; margin-right: 20px">核销门店</div>
          <div>{{ veriferInfo.final_verif_hierarchy_name }}</div>
        </div>
      </div>
      <div style="margin-top: 15px; text-align: center">
        <el-button
          type="primary"
          plain
          @click="cancelVerifer"
          v-if="veriferInfo.is_verif === 20"
          >取消</el-button
        >
        <el-button
          type="primary"
          @click="handleWriteOff"
          v-if="veriferInfo.is_verif === 10"
          >核销</el-button
        >
        <el-button
          type="primary"
          @click="cancelVerifer"
          v-if="veriferInfo.is_verif === 20"
          >确认</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getVerifList,
  checkVerificationCode,
  verifDetails,
} from "@/api/marketing/activity/index";
export default {
  data() {
    return {
      height: "55px",
      cond: {},
      exportLoading: false,
      verifList: [],
      verifCond: {
        page: 1,
        page_num: 15,
      },
      verifTotal: 0,
      verif_total_num: 0,
      un_verif: 0,
      verif: 0,
      verifGoodsVisiable: false,
      verifCode: null,
      visiable: false,
      veriferInfo: {},
    };
  },
  created() {
    this.getVerifList();
  },
  methods: {
    toVerif(row) {
      this.market_drawn_user_id = row.market_drawn_user_id;
      this.verifGoodsVisiable = true;
    },
    handleCloseVerif() {
      this.verifGoodsVisiable = false;
      this.getVerifList();
    },
    /*
     *   核销记录
     */
    getVerifList() {
      getVerifList({
        market_id: this.store_activity_id,
        ...this.verifCond,
      }).then((res) => {
        if (res.code === 1) {
          this.verifList = res.data.list.list;
          this.verifTotal = res.data.list.total;
          (this.verif_total_num = res.data.total_num),
            (this.verif = res.data.verif);
          this.un_verif = res.data.un_verif;
        }
      });
    },
    cancelVerifer() {
      this.veriferInfo = {};
      this.visiable = false;
    },
    handleWriteOff() {
      checkVerificationCode({ code: this.veriferInfo.verif_code }).then(
        (res) => {
          if (res.code === 1) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.cancelVerifer();
            this.getVerifList();
          }
        }
      );
    },
    resetVerifList() {
      this.verifCond = {
        page: 1,
        page_num: 15,
      };
      getVerifList({
        market_id: this.store_activity_id,
        ...this.verifCond,
      }).then((res) => {
        if (res.code === 1) {
          this.verifList = res.data.list.list;
          this.verifTotal = res.data.list.total;
          (this.verif_total_num = res.data.total_num),
            (this.verif = res.data.verif);
          this.un_verif = res.data.un_verif;
        }
      });
    },
    handleHeight() {
      if (parseInt(this.offsetHeight) <= 62) {
        this.$message.warning("没有更多条件");
        return;
      }
      this.height = this.height === "55px" ? this.offsetHeight : "55px";
    },
    /*
     *  导出
     */
    handleExport() {},
    handleCurrentVerifChange(val) {
      this.verifCond.page = val;
      this.getVerifList();
    },
    handleVerif(row) {
      this.visiable = true;
      verifDetails({ code: row.verif_code }).then((res) => {
        if (res.code === 1) {
          this.veriferInfo = res.data.info;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.wrapper {
  transition: all 0.3s;
  overflow: hidden;
  flex: 1;
  .middle {
    display: flex;
    overflow: hidden;
  }
}

.writeData {
  display: flex;
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  border-top: none;
  border: 1px solid #e8e8e8;
  border-top: none;
  border-radius: 0 0 4px 4px;
  background-color: white;
  .line {
    margin: auto 30px;
    width: 1px;
    height: 26px;
    background: #e9e9e9;
  }
}
.headImg {
  margin: 0 auto;
  width: 32px;
  height: 32px;
  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
}
.goods_Img {
  margin: 8px auto;
  text-align: center;
  width: 54px;
  height: 54px;
  opacity: 1;
  img {
    width: 54px;
    height: 54px;
  }
}
</style>